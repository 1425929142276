.data-mapper-content {
  margin-bottom: 60px;
}

.point-mapper-content {
  position: unset;
  margin-bottom: 60px;
}

.rich-data-nav__list {
  margin-bottom: 60px;
}

.watermark-wrapper {
  position: absolute;
  left: 10px;
  bottom: 10px;
}

.cc-license-wrapper {
  position: absolute;
  left: 10px;
  bottom: -5px;
  font-size: 10px;
}

.right-panel-toggles {
  position: fixed;
  right: 0;
  top: 80px;
}

.filter-row-label {
  color: #707070;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.72px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.remove-filter-row {
  width: 18px;
  height: 20px;
  background: none;
  border: none;
  margin-left: 0px;
  align-self: center;
}


.tutorial-button-container {
  height: 100%;
  position: fixed;
  transform: rotate(-90deg);
  display: flex;
  transform-origin: 100% 100%;
  top: auto;
  bottom: 0;
  left: auto;
  right: 0;
}

.profile-indicator__filters-wrapper {
  width: 90% !important;
}


.header-containers {
  width: 250px;
  justify-content: end;
  display: flex;
}

.current-view-container {
  display: inline-flex;
  align-items: center;
}

.tabular-button-container {
  display: inline-flex;
  align-items: center;
  margin-left: 5px;
}

.separator {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #f4f4f4;
  margin: 1em 0 3px;
  padding: 0;
}

.search-result-point-container {
  max-height: 150px;
  overflow-y: scroll;

  .search-result-point-row {
    background-color: #f0f0f0;
    margin-bottom: 4px;
    margin-top: 2px;
    display: flex;
    cursor: pointer;

    &:hover {
      background-image: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .1))
    }

    .search-result-point-column {
      padding-left: 12px;
      padding-right: 12px;
      font-size: 14px;
      height: 32px;
      line-height: 32px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      button.point-result-pin {
        background-color: transparent;
        height: 100%;
        border-left: 5px solid #fff;
        text-align: center;
        width: 100%;
        padding: 0;
        margin: 0 auto;
      }

      .MuiSelect-select i.material-icons {
        font-size: 18px;
        position: absolute;
        left: 10px;
        bottom: 9px;
      }

      .MuiSelect-select .dd-text-container {
        margin-left: 20px;
      }

      .MuiInputBase-formControl {
        height: 32px;
      }
    }
  }
}

.search-result-point-table-header {
  display: flex;

  .search-result-point-table-header-column {
    width: 25%;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 9px;
    color: #707070;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.warning-modal{
  position: fixed;
}

// CSS for new filters
.filter-container {
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.10);
  padding: 6px;
  gap: 8px;
  align-self: stretch;
  margin-bottom: 10px;
}

.mapping-options__add-filter.disabled, .profile-indicator__new-filter.disabled, .location__facilities_download-all.disabled {
  opacity: 0.4;
}

.filter-container .profile-indicator__filter,
.filter-container .mapping-options__filter,
.filter-container .point-filters__filter {
  margin-right: 0px;
  max-width: 50%;
}

.filter-container .remove-filter-row:hover {
  background-color: #fff;
}

.filter-container .remove-filter-row {
  margin-left: 4px;
  margin-right: 2px;
}

.filter-container .profile-indicator__filter-buttons {
  width: auto !important;
}

.filter-container .filter-divider {
  margin: auto;
}

.profile-indicator__new-filter {
  margin-right: 0px !important;
}

.filter-container .mapping-options__remove-filter,
.filter-container .point-filters__remove-filter {
  position: unset;
  margin-left: 0px;
}

// END of CSS for new filters


@media (max-width: 480px) {
  .right-panel-toggles {
    top: 370px;
  }

  .choropleth-method-description, .filters__header_label {
    display: none !important;
  }
}
